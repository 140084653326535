import React from 'react'
import { buildImageObj, isExternalUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockRaw from './block-raw'
import Container from './container'

import styles from './component-one-column-with-image.module.css'

function OneColumnWithImage (props) {
  return (
    <Container>
      <div className={styles.wrap}>
        <div className={styles.image}>
          <img src={imageUrlFor(buildImageObj(props.image)).url()} />
        </div>
        <div className={styles.content}>
          <h3 className={`${styles.headline} ${styles.bullet}`}>{props.title}</h3>
          <BlockRaw text={props.body} />
          {props.buttonLink && (
            <a target={isExternalUrl(props.buttonLink) ? '_blank' : '_self'} href={props.buttonLink} className={`${styles.button} ${styles.primary}`} rel='noreferrer'>{props.buttonLabel}</a>
          )}
        </div>
      </div>
    </Container>
  )
}

export default OneColumnWithImage
