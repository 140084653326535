import React from 'react'
import BlockRaw from './block-raw'
import Container from './container'

import styles from './component-three-columns-text.module.css'
import { isExternalUrl } from '../lib/helpers'

function ThreeColumnsText (props) {
  return (
    <Container>
      <div className={styles.columns}>
        <div className={styles.content}>
          <h5 className={`${styles.headline} ${styles.bullet}`}>{props.columnOneTitle}</h5>
          <BlockRaw text={props.columnOneBody} />
          {props.columnOneButtonLink && (
            <a target={isExternalUrl(props.columnOneButtonLink) ? '_blank' : '_self'} href={props.columnOneButtonLink} className={`${styles.button} ${styles.primary}`} rel='noreferrer'>{props.columnOneButtonLabel}</a>
          )}
        </div>
        <div className={styles.content}>
          <h5 className={`${styles.headline} ${styles.bullet}`}>{props.columnTwoTitle}</h5>
          <BlockRaw text={props.columnTwoBody} />
          {props.columnTwoButtonLink && (
            <a target={isExternalUrl(props.columnTwoButtonLink) ? '_blank' : '_self'} href={props.columnTwoButtonLink} className={`${styles.button} ${styles.primary}`} rel='noreferrer'>{props.columnTwoButtonLabel}</a>
          )}
        </div>
        <div className={styles.content}>
          <h5 className={`${styles.headline} ${styles.bullet}`}>{props.columnThreeTitle}</h5>
          <BlockRaw text={props.columnThreeBody} />
          {props.columnThreeButtonLink && (
            <a target={isExternalUrl(props.columnThreeButtonLink) ? '_blank' : '_self'} href={props.columnThreeButtonLink} className={`${styles.button} ${styles.primary}`} rel='noreferrer'>{props.columnThreeButtonLabel}</a>
          )}
        </div>
      </div>
    </Container>
  )
}

export default ThreeColumnsText
